import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
export default function StepperControl({ handleClick, currentStep, steps }) {
  const navigate = useNavigate();
  const submitBooking = () => {

    console.log("Submit Booking")
    const BookingInfo = sessionStorage.getItem("selectionData")
    let accessToken = sessionStorage.getItem("access_token")
    if (accessToken != null) {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://localhost:8000/api/schedule/',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken
        },
        data: BookingInfo
      };

      axios.request(config)
        .then((response) => {
          if (response.status === 200) {

            console.log(JSON.stringify(response.data));

            toast.success('Schedule Successfully Created!');

            // Delay navigation slightly so toast is visible on the target page
            setTimeout(() => {
              navigate("/platform/schedule/list");
            }, 5000);
          }
          else {
            toast.error('An error occurred, please try again later!');
          }
        })
        .catch((error) => {
          toast.error('An error occurred, please try again later!');
        });


    }
  }
  return (
    <div className="mt-2 flex flex-col justify-between px-3 pt-2 md:flex-row md:px-8">
      {currentStep === steps.length - 3 ? (
        <div />
      ) : (
        <button
          onClick={() => handleClick()}
          className={`mb-3 cursor-pointer rounded-xl bg-lightPrimary px-16 py-2 font-medium text-navy-700 transition duration-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20 md:mb-0`}
        >
          Prev
        </button>
      )}

      <button
        onClick={currentStep !== steps.length ? () => handleClick("next") : () => submitBooking()}
        className="cursor-pointer rounded-xl bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
      >
        {currentStep !== steps.length ? "Next" : "Submit"}
      </button>
      <ToastContainer />
    </div>
  );
}

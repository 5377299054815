export const columnsDataOrders = [
  {
    Header: 'FLEET #',
    accessor: 'fleetNumber',
  },
  {
    Header: 'MAKE',
    accessor: 'manufacturer',
  },
  {
    Header: 'MODEL',
    accessor: 'model',
  },
  {
    Header: 'WEIGHT',
    accessor: 'weight',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'DAY PRICE',
    accessor: 'dayRate',
  },
  {
    Header: 'ACTIONS',
    accessor: 'actions',
  },
];

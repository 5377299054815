import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import DropDownNew from "components/fields/dropdownNew";
import { useState, useEffect } from "react";

const BookingInfo = () => {
  const [clientName, setClientName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [siteAddress, setSiteAddress] = useState("");
  const [sitePostcode, setSitePostcode] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [deliveryRequired, setDeliveryRequired] = useState("Yes");
  const [additionalNotes, setAdditionalNotes] = useState("");

  const selection = () => {
    const bookingInfo = {
      "contact": {
        "name": clientName,
        "phone": phoneNumber,
        "email": emailAddress
      },
      "site": {
        "address": siteAddress,
        "postcode": sitePostcode
      },
      "deliveryRequired": deliveryRequired,
      "notes": additionalNotes,
      "machineInfo": JSON.parse(sessionStorage.getItem("machineselection"))
    }
    sessionStorage.setItem("bookingInfo", JSON.stringify(bookingInfo))

  }
  useEffect(() => {
    // This effect will be triggered whenever MachineSelection is updated
    selection();
  }, [clientName, phoneNumber, siteAddress, sitePostcode, emailAddress, deliveryRequired, additionalNotes]);

  const options = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" }
  ];
  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Booking Info
      </h4>

      {/* content */}
      {/* inputs */}
      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-4"
            label="Client Name"
            placeholder="eg. Joe Smith"
            id="clientname"
            type="text"
            onChange={(e) => setClientName(e.target.value)}
          />
          <InputField
            extra="mb-4"
            label="Phone Number"
            placeholder="0712346711"
            id="phonenumber"
            type="phone"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <InputField
            extra="mb-4"
            label="Site Address"
            placeholder="eg. 123 Scott Road, Manchester, UK"
            id="address"
            type="text"
            onChange={(e) => setSiteAddress(e.target.value)}
          />
          <InputField
            extra="mb-4"
            label="Site Postcode"
            placeholder="M1 2AH"
            id="postcode"
            type="text"
            onChange={(e) => setSitePostcode(e.target.value)}
          />
        </div>
        {/* right side */}
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-4"
            label="Email Address"
            placeholder="joesmith@outlook.com"
            id="emailaddress"
            type="text"
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          <DropDownNew
            extra="mb-3 "
            label="Delivery Required"
            placeholder="Select Delivery Required"
            id="collection"
            type="text"
            value={deliveryRequired}
            options={options}
            onChange={(e) => setDeliveryRequired(e.target.value)}
          />

          {/* text area */}
          <TextField
            label="Additional Notes"
            placeholder="Any additional notes about the client, site or any information that will help the driver. E.g Access times"
            id="textarea"
            cols="30"
            rows="7"
            onChange={(e) => setAdditionalNotes(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingInfo;

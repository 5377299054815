import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import DropDownNew from "components/fields/dropdownNew";

import { useState, useEffect } from "react";
import DatePickerInput from "components/fields/DatePicker";

import { columnsDataOrders } from '../../tableList/variables/columnsDataOrders';
import tableDataOrders from '../../tableList/variables/tableDataOrders.json';
import SearchTableOrders from '../../tableList/components/SearchTableOrders';
import axios from "axios";
const EquipmentAvailability = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [MachineSelection, setMachineSelection] = useState("Exacator")
  const [availability, setAvailability] = useState(null)
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }
  const selection = () => {

    const data = {
      "start": startDate.toISOString(),
      "end": endDate.toISOString(),
      "type": MachineSelection,
    }
    console.log(data)
    sessionStorage.setItem("machineselection", JSON.stringify(data))
  }

  useEffect(() => {
    const machineselection = JSON.parse(sessionStorage.getItem("machineselection"));
    console.log(machineselection)
    let accessToken = sessionStorage.getItem("access_token")
    if (accessToken != null) {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://localhost:8000/api/availability/',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken
        },
        data: machineselection
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data.available_equipment));
          setAvailability(response.data.available_equipment)
        })
        .catch((error) => {
          console.log(error);
        });


    }
  }, [])
  if (availability != null) {
    return (
      <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
        {/* Header */}
        <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
          Equipment Availability
        </h4>

        {/* content */}
        {/* inputs */}
        <div className="mt-6 grid grid-cols-1 gap-3">
          <div className="col-span-2 md:col-span-1">
            <SearchTableOrders
              tableData={availability}
              columnsData={columnsDataOrders}
            />



          </div>
          {/* right side 
          <div className="col-span-2 md:col-span-1">
            <DatePickerInput
              extra="mb-4"
              label="End"
              placeholder={endDate}
              id="end"
              type="text"
              onChange={(date) => { setEndDate(date); selection() }}
            />
            
  
  
          </div>*/}
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
        {/* Header */}
        <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
          Equipment Availability
        </h4>

        {/* content */}
        {/* inputs */}
        <div className="mt-6 grid grid-cols-1 gap-3">
          <div className="col-span-2 md:col-span-1">




          </div>
          {/* right side 
            <div className="col-span-2 md:col-span-1">
              <DatePickerInput
                extra="mb-4"
                label="End"
                placeholder={endDate}
                id="end"
                type="text"
                onChange={(date) => { setEndDate(date); selection() }}
              />
              
    
    
            </div>*/}
        </div>
      </div>
    );

  }

};

export default EquipmentAvailability;

export const serviceColumns = [
    {
        Header: 'Equipment Ref',
        accessor: 'fleetNumber',
    },
    {
        Header: 'Equipment Type',
        accessor: 'type',

    },
    {
        Header: 'Service Required',
        accessor: 'nextService',
    },
    {
        Header: 'Current Status',
        accessor: 'status',
    },
    {
        Header: 'Hours Worked',
        accessor: 'hoursWorked',
    },
];

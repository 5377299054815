export const tableColumnsReceipt = [
  {
    Header: 'id',
    accessor: 'id',
  },
  {
    Header: 'Equipment Number',
    accessor: 'equipmentNumber',
  },
  {
    Header: 'Rate',
    accessor: 'dayRate',
  },
  {
    Header: 'Hire Start',
    accessor: 'start',
  },
  {
    Header: 'Hire End',
    accessor: 'end',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
];

import Transfer from "components/dataDisplay/Transfer";
import React from "react";
import { BsArrowRight } from "react-icons/bs";
import avatar1 from "assets/img/avatars/JCB1.jpeg";
import avatar2 from "assets/img/avatars/JCBCabbedDumper.jpeg";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import Card from "components/card";

function EquipmentInStock(props) {
  const { columnsData } = props;
  return (
    <Card extra={"!p-5 h-full"}>
      <h4 className="mb-[22px] ml-px text-lg font-bold text-navy-700 dark:text-white">
        Equipment In Stock
      </h4>
      {columnsData.map(function (data) {

        const initialImage = data.images.length > 0 ? `http://localhost:8000${data.images[0].image}` : "https://via.placeholder.com/800x600?text=No+Images+Available";
        return (
          <Transfer
            name={data.fleetNumber}
            date={data.model}
            sum={"£" + data.dayRate}
            avatar={initialImage}
            key={data.id}
          />
        )
      })}



      <div className="mb-auto" />
      <div className="flex w-full items-center justify-end gap-1 hover:cursor-pointer">
        <div className="text-sm font-bold text-blue-950 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
          View all equipment
        </div>
        <div className="text-xl font-bold text-blue-950 transition-all hover:translate-x-1 hover:cursor-pointer dark:text-white">
          <BsArrowRight />
        </div>
      </div>
    </Card>
  );
}

export default EquipmentInStock;

import Card from "components/card";
import TimelineItem from "components/dataDisplay/TimelineItem";
import { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from 'axios';


const TimeLine = (props) => {
  const { calendarData } = props;
  const navigate = useNavigate();
  function getDayName(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: 'long' });
  }
  const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append('equipmentProfileImage', imageFile);

    try {
      const response = await axios.post('http://localhost:8000/api/equipment/images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    uploadImage(file);
  };
  return (
    <Card extra={"flex w-full flex-col max-w-[90vw] rounded-md font-bold px-4 py-4  "}>
      {/* Header */}
      <div className="mb-[20px] flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Timeline
          <p className="text-base font-medium text-gray-600">
            See your booking timeline:
          </p>
        </h4>

        <button
          onClick={() => { navigate("/platform/booking/new") }}
          className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
        >
          <AiOutlinePlus />
        </button>
        <input
          type="file" onChange={handleFileChange}
          className="linear flex items-center justify-center rounded-md bg-lightPrimary px-8 py-1 text-lg text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
        >

        </input>
      </div>

      {/* Timeline items */}
      <div className="mt-[30px]">
        {calendarData.map(function (data) {

          var dateStr = data.start;
          var day = getDayName(dateStr, "en-uk");
          dateStr = dateStr.split("-")
          dateStr = String(dateStr[2]).split("T")
          var monthString = data.start.split("-")

          return (
            <TimelineItem
              mb="mb-[16px]"
              title={"Order ID: " + data.id}
              day={dateStr[0] + "/" + monthString[1]}
              weekday={day.substring(0, 3)}
              hours={data.startHours}
            />
          )
        })}

      </div>
    </Card>
  );
};

export default TimeLine;

import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { differenceInDays } from 'date-fns';
import formatDate from "components/actions/ConvertDate";

function padID(id) {
  return String(id).padStart(4, '0');
}
const ReceiptTable = (props) => {


  const { columnsData, tableData, equipmentData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const abbreviation = sessionStorage.getItem("abbreviation");
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;
  return (
    <div className="h-full w-full overflow-x-scroll py-[45px] dark:!bg-navy-800  md:px-9 lg:overflow-x-hidden">
      <table {...getTableProps()} className="w-full text-gray-600">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr className="" {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th
                  className="border-b border-gray-200 px-4 text-start dark:!border-white/10"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                >
                  <div className="pb-[11px] text-xs font-bold uppercase text-gray-600">
                    {column.render("Header")}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody className="" {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr className="" {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "id") {
                    data = (
                      <div className="">
                        <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                          #{abbreviation + padID(cell.value)}
                        </p>

                      </div>
                    );
                  }
                  else if (cell.column.Header === "Equipment Number") {

                    if (cell.value === equipmentData[0].fleetNumber) {
                      data = (
                        <div className="">
                          <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                            {cell.value}
                          </p>

                        </div>
                      );
                    }

                  }
                  else if (cell.column.Header === "Rate") {

                    data = (
                      <div className="">
                        <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                          £{equipmentData[0].dayRate}
                        </p>

                      </div>
                    );


                  } else if (cell.column.Header === "Hire Start") {
                    const date = formatDate(cell.value);

                    data = (
                      <div className="">
                        <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                          {date}
                        </p>

                      </div>
                    );


                  } else if (cell.column.Header === "Hire End") {
                    const date = formatDate(cell.value);

                    data = (
                      <div className="">
                        <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                          {date}
                        </p>

                      </div>
                    );


                  }
                  else if (cell.column.Header === "Amount") {
                    const days = differenceInDays(new Date(row.original.end), new Date(row.original.start));

                    data = (
                      <div className="">
                        <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                          £{equipmentData[0].dayRate * days}
                        </p>

                      </div>
                    );


                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={index}
                      className="font-sm mt-[20px] border-b border-gray-200 px-[23px] py-[25px] dark:!border-white/10"
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReceiptTable;

import React from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick

function EventCalendar(props) {
  const { calendarData, initialDate } = props;

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin]}

      header={{
        left: 'prev, next',
        center: 'title',
        right: 'timeGridWeek'
      }}

      initialView="dayGridMonth"
      contentHeight="600px"
      events={calendarData}
      initialDate={initialDate}
      eventContent={renderEventContent}
      editable={false}
      minHeight="400px"
      height="100%"
      slotWidth={10}
      customIcons={false}
      eventClick={(event) => { alert(event.event.id) }}
    />
  );
}
function renderEventContent(eventInfo) {

  return (
    <>
      <b>OrderID: </b>
      <b>{eventInfo.event.id}</b>


    </>
  )
}
export default EventCalendar;

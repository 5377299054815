export const todoColumns = [
    {
        Header: 'Title',
        accessor: 'title',
    },
    {
        Header: 'Description',
        accessor: 'description',

    },
    {
        Header: 'Type',
        accessor: 'type',
    },
    {
        Header: 'Current Status',
        accessor: 'completed',
    },
    {
        Header: 'Due Date',
        accessor: 'dueDate',
    },
    {
        Header: 'Action',
        accessor: 'actionURL',
    },
];

import InputField from "components/fields/InputField";
import TagsField from "components/fields/TagsField";
import { set } from "date-fns";
import { useEffect, useState } from "react";


const Pricing = () => {
  const [price, setPrice] = useState("");
  const [equipmentRef, setEquipmentRef] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deliveryRequired, setDeliveryRequired] = useState("");
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("bookingInfo"))
    setEquipmentRef(data.machineInfo.fleetNumber)
    setStartDate(data.machineInfo.start)
    setEndDate(data.machineInfo.end)
    setDeliveryRequired(data.deliveryRequired)
  }, []);
  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h1 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Booking Confirmation
      </h1>
      {/* inputs */}
      <div className="mt-10 grid h-full w-full grid-cols-1 gap-3 md:grid-cols-2">
        {/* left side */}
        <div className="flex h-fit flex-col gap-3 md:col-span-2 md:grid md:grid-cols-3">
          <InputField
            label="Equipment Ref"
            placeholder={equipmentRef}
            id="equipmentRef"
            type="text"
            disabled={true}
          />
          <InputField
            label="Delivery Required"
            placeholder={deliveryRequired}
            id="equipmentRef"
            type="text"
            disabled={true}
          />

        </div>
        <InputField
          label="Start Date"
          placeholder={startDate.split("T")[0].split("-").reverse().join("/")}
          id="startDate"
          type="text"
          disabled={true}
        />
        <InputField
          label="End Date"
          placeholder={endDate.split("T")[0].split("-").reverse().join("/")}
          id="endDate"
          type="text"
          disabled={true}
        />

      </div>
    </div>
  );
};

export default Pricing;

import { MdShare, MdEdit, MdDownload } from "react-icons/md";
import Content from "./Content";
import Card from "components/card";
import React from "react";

function padID(id) {
  return String(id).padStart(4, '0');
}

const Receipt = (data) => {
  const [receiptData, setReceiptData] = React.useState()
  const abbreviation = sessionStorage.getItem("abbreviation");
  React.useEffect(() => {
    setReceiptData(data)
    console.log(receiptData)
  }, [])
  return (
    <Card extra={"w-full h-full px-6 py-6"}>
      {/* Banner */}
      <div
        //style={{ backgroundImage: `url(${Details})` }}
        className="flex w-full items-center justify-between rounded-2xl bg-blue-950 bg-cover bg-no-repeat px-6 pb-10 pt-11 md:!h-[180px] md:items-start md:pl-[50px] md:pr-[57px] 2xl:!h-[190px]"
      >
        <div className="">
          <h2 className="text-sm font-bold text-white sm:text-lg md:text-[30px] xl:!text-[40px] 2xl:pt-3">
            Order {abbreviation + padID(data.data[0].id)}
          </h2>
          <p className="text-xs text-white sm:text-sm md:mt-1 md:text-xl xl:!text-xl 2xl:pt-1">

          </p>
        </div>
        {/* buttons */}
        <div className="mt-[13px] flex gap-[12px] md:mt-0">

          <a href="https://docdro.id/jNrQiMH" target="blank">
            <button className="flex items-center justify-center rounded-lg bg-navy-500 p-2 text-xl text-white transition-opacity hover:cursor-pointer hover:opacity-80">
              <MdDownload />
            </button>
          </a>

        </div>
      </div>
      {/* content */}
      <div className="h-full w-full">
        <Content data={data.data} equipmentData={data.equipment} siteContact={data.siteContact} />
      </div>
    </Card>
  );
};

export default Receipt;

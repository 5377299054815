import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import DropDownNew from "components/fields/dropdownNew";

import { useState, useEffect } from "react";
import DatePickerInput from "components/fields/DatePicker";
const MachineSelection = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [MachineSelection, setMachineSelection] = useState("")
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }
  const selection = () => {
    console.log(MachineSelection)
    const data = {
      "start": startDate.toISOString(),
      "end": endDate.toISOString(),
      "type": MachineSelection,
    }

    sessionStorage.setItem("machineselection", JSON.stringify(data))
  }

  useEffect(() => {
    // This effect will be triggered whenever MachineSelection is updated
    selection();
  }, [MachineSelection, startDate, endDate]);

  const options = [
    { label: "Excavator", value: "Excavator" },
    { label: "Dumper", value: "Dumper" }
  ];
  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Equipment & Date Selection
      </h4>

      {/* content */}
      {/* inputs */}
      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <DatePickerInput
            extra="mb-4"
            label="Start"
            placeholder={startDate}
            id="start"
            type="text"
            onChange={(date) => { setStartDate(date); }}

          />
          <DropDownNew
            extra="mb-3 "
            label="Equipment Type"
            placeholder={"Select Equipment Type"}
            id="collection"
            type="text"
            value={MachineSelection}
            options={options}
            onChange={(option) => {

              setMachineSelection(option.target.value);

            }}
          />




        </div>
        {/* right side */}
        <div className="col-span-2 md:col-span-1">
          <DatePickerInput
            extra="mb-4"
            label="End"
            placeholder={endDate}
            id="end"
            type="text"
            onChange={(date) => { setEndDate(date); }}
          />



        </div>
      </div>
    </div>
  );
};

export default MachineSelection;

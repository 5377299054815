import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import DropDownNew from "components/fields/dropdownNew";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Information = (equipment) => {
  const data = equipment.equipment.equipment
  const statusMap = {
    1: 'In Stock',
    2: 'Booked',
    3: 'Maintenance/Service'
  };
  const valueMap = {
    'In Stock': 1,
    'Booked': 2,
    'Maintenance/Service': 3
  }
  const [fleetNumber, setFleetNumber] = useState(data.fleetNumber)
  const [weight, setWeight] = useState(data.weight)
  const [dayRate, setDayRate] = useState(data.dayRate)
  const [type, setType] = useState(data.type)
  const [status, setStatus] = useState(data.status)
  const navigate = useNavigate();
  const submitData = () => {
    const accessToken = sessionStorage.getItem("access_token");
    const data = {
      "fleetNumber": fleetNumber,
      "weight": weight,
      "dayRate": dayRate,
      "status": status,
      "type": type

    }
    console.log(data)
    if (accessToken) {
      const headers = {
        "Authorization": "Token " + accessToken,
        "Accept": 'application/json',
        'Content-Type': 'application/json',
      };
      axios.patch("http://localhost:8000/api/equipment/", data, { headers: headers }).then((res) => {
        if (res.status == 200) {
          //console.log(res.data)
          toast.success('Equipment information successfully updated!');

          // Delay navigation slightly so toast is visible on the target page
          setTimeout(() => {
            navigate("/platform/equipment/list");
          }, 5000);
        }
        else {
          console.log(res.data)
        }
      }).catch((e) => {
        console.log(e)
      })
    }
  }
  const MachineOptions = [
    { label: "EXCAVATOR", value: "EXCAVATOR" },
    { label: "DUMPER", value: "DUMPER" }
  ];
  const StatusOptions = [
    { label: "In Stock", value: 1 },
    { label: "Booked", value: 2 },
    { label: "Maintenance/Service", value: 3 }
  ];
  return (
    <Card extra={"w-full px-[20px] py-[22px] h-fit"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Equipment Information
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change your equipment information
        </p>
      </div>
      {/* inputs */}
      <div className="mt-9 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-3"
            label="Fleet Number"
            placeholder={fleetNumber}
            id="name"
            type="text"
            onChange={(e) => {
              setFleetNumber(e.target.value)
            }}
          />
          <InputField
            extra="mb-3"
            label="Weight"
            placeholder={weight}
            id="weight"
            type="text"
            onChange={(e) => {
              setWeight(e.target.value)
            }}
          />
          <InputField
            label="Day Rate"
            placeholder={"£" + dayRate}
            id="color"
            type="text"
            onChange={(e) => {
              setDayRate(e.target.value)
            }}
          />
        </div>
        {/* right side */}
        <div className="col-span-2 md:col-span-1">
          <DropDownNew
            extra="mb-3 "
            label="Equipment Type"
            placeholder={String(type).toUpperCase()}
            id="collection"
            type="text"
            options={MachineOptions}
            onChange={(option) => {

              setType(option.target.value)

            }}
          />
          <DropDownNew
            extra="mb-3 "
            label="Status"
            placeholder={statusMap[status]}
            id="collection"
            type="text"
            options={StatusOptions}
            onChange={(option) => {

              const data = valueMap[option.target.value]

              setStatus(option.target.value)

            }}
          />
        </div>
      </div>

      <div className="ml-auto flex flex-col items-center gap-4 md:flex-row">

        <button className="rounded-xl bg-brand-500 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" onClick={() => { submitData() }}>
          Save Changes
        </button>
      </div>
      <ToastContainer />
    </Card>
  );
};

export default Information;

/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import SidebarCard from "components/sidebar/components/SidebarCard";
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import avatar4 from "assets/img/users/default-user.webp";
import routes from "routes";
import Card from "components/card";
import { useState, useEffect } from "react";
function SidebarHorizon(props) {
  const { open, onClose, variant, mini, hovered, setHovered } = props;
  const [fullname, setFullName] = useState("")
  const [companyName, setCompanyName] = useState("")

  const navigate = useNavigate();
  useEffect(() => {
    let fullname_session = sessionStorage.getItem("fullname")
    let company_session = sessionStorage.getItem("company")
    if (fullname_session != null || company_session != null) {
      //check access token is valid
      setFullName(fullname_session)
      setCompanyName(company_session)
    } else {
      navigate("/auth/login")
    }
  }, []);
  return (
    <div
      className={`sm:none ${mini === false
        ? "w-[285px]"
        : mini === true && hovered === true
          ? "w-[285px]"
          : "w-[285px] xl:!w-[120px]"
        } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${variant === "auth" ? "xl:hidden" : "xl:block"
        } ${open ? "" : "-translate-x-[105%]"}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card
        extra={`ml-3 w-full h-[96.5vh] sm:mr-4 sm:my-4 m-7 !rounded-[20px]`}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
                ? renderView
                : renderViewMini
          }
        >
          <div className="flex h-full flex-col justify-between">
            <div>

              <div className={`ml-[52px] mt-[44px] flex items-center `}>
                <div
                  className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-grey-950 dark:text-white ${mini === false
                    ? "block"
                    : mini === true && hovered === true
                      ? "block"
                      : "hidden"
                    }`}
                >
                  Plant <span className="font-medium text-blue-950">ops</span>
                </div>
                <div
                  className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${mini === false
                    ? "hidden"
                    : mini === true && hovered === true
                      ? "hidden"
                      : "block"
                    }`}
                >

                </div>
              </div>
              <div className="mt-[58px] mb-7 h-px bg-gray-200 dark:bg-white/10" />
              {/* Nav item */}
              <ul>
                <Links mini={mini} hovered={hovered} routes={routes} />
              </ul>

            </div>

            <div className="mt-[28px] mb-[30px]">
              <div className="flex justify-center">
                {/*<SidebarCard mini={mini} hovered={hovered} />*/}
              </div>
              {/* Sidebar profile info */}
              <div className="mt-5 flex items-center justify-center gap-3">
                <div className="h-12 w-12 rounded-full bg-blue-200">
                  <img src={avatar4} className="rounded-full" alt="avatar" />
                </div>
                <div
                  className={`ml-1 ${mini === false
                    ? "block"
                    : mini === true && hovered === true
                      ? "block"
                      : "block xl:hidden"
                    }`}
                >
                  <h4 className="text-base font-bold text-navy-700 dark:text-white">
                    {fullname}
                  </h4>
                  <p className="text-sm font-medium text-gray-600">
                    {companyName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </Card>
    </div>
  );
}

export default SidebarHorizon;

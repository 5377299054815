import Details from "./components/Details";
import OrderStatus from "./components/OrderStatus";
import Receipt from "./components/Receipt";
import { useLocation } from "react-router-dom";
import axios from "axios";
import React from "react";

const OrderDetails = () => {
  const [bookingData, setBookingData] = React.useState([]);
  const [equipmentData, setEquipmentData] = React.useState({});
  const [siteContactData, setSiteContactData] = React.useState({});
  const [siteData, setSiteData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const location = useLocation();
  const data = location.state;
  // const data = {
  // booking: 12
  // }

  React.useEffect(() => {
    console.log("BOOKING", data.booking)

    const fetchData = async () => {
      const accessToken = sessionStorage.getItem("access_token");
      const headers = {
        "Authorization": "Token " + accessToken,
        "Accept": 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      };

      if (accessToken) {
        try {
          const bookingResponse = await axios.get(`http://localhost:8000/api/booking/?id=${data.booking}`, { headers });
          const equipmentResponse = await axios.get(`http://localhost:8000/api/equipment/?id=${bookingResponse.data.bookings[0].equipmentNumber}`, { headers });
          const siteContactResponse = await axios.get(`http://localhost:8000/api/site-contacts/?id=${bookingResponse.data.bookings[0].siteContact}`, { headers });
          const siteResponse = await axios.get(`http://localhost:8000/api/sites/?id=${bookingResponse.data.bookings[0].siteNumber}`, { headers });
          console.log("booking", bookingResponse.data)
          console.log("equipment", equipmentResponse.data)
          console.log("sitecontact", siteContactData)
          console.log("site", siteResponse.data)

          setSiteContactData(siteContactResponse.data.site_contact);

          setEquipmentData(equipmentResponse.data.equipment);
          setBookingData(bookingResponse.data.bookings);
          setSiteData(siteResponse.data.sites);
          setIsLoading(false);
        } catch (error) {
          console.error('Failed to fetch data:', error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mt-3 grid h-full w-full grid-cols-7 gap-[20px] rounded-[20px]">
      <div className="z-0 col-span-7 lg:col-span-5">
        <Receipt data={bookingData} equipment={equipmentData} siteContact={siteContactData} />
      </div>

      <div className="z-0 col-span-7 lg:col-span-5">
        <Details siteContact={siteContactData} siteData={siteData} />
      </div>
    </div>
  );
};

export default OrderDetails;

export const tableBookings = [
  {
    Header: 'Booking Ref',
    accessor: 'id',
  },
  {
    Header: 'Equipment Ref',
    accessor: 'equipmentNumber',

  },
  {
    Header: 'Hire Start',
    accessor: 'start',
  },
  {
    Header: 'Hire End',
    accessor: 'end',
  },
  {
    Header: 'Collection Required',
    accessor: 'deliveryRequired',
  },
  {
    Header: '',
    accessor: 'button',
  },
];

import { useState } from "react";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import BookingInfo from "./components/steps/BookingInfo";
import MachineSelection from "./components/steps/MachineSeletion";
import Pricing from "./components/steps/Pricing";
import Card from "components/card";
import EquipmentAvailability from "./components/steps/Availability";
import { useEffect } from "react";
import axios from "axios";
const NewSchedule = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [vehicleData, setVehicleData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const steps = [
    { stepNo: 1, name: "Selection" },
    { stepNo: 2, name: "Confirmation" },
  ];

  const displayStep = (step) => {
    switch (step.stepNo) {
      case 1:
        return <MachineSelection data={vehicleData} drivers={driverData} />;
      case 2:
        return <Pricing />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  useEffect(() => {
    const accessToken = sessionStorage.getItem("access_token");
    const fetchData = async () => {
      if (accessToken) {
        const headers = {
          "Authorization": "Token " + accessToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        try {
          // Fetch the vehicle data
          const vehicleResponse = await axios.get("http://localhost:8000/api/vehicle/", { headers });
          const driverResponse = await axios.get("http://localhost:8000/api/driver/", { headers });
          setVehicleData(vehicleResponse.data.vehicle);
          setDriverData(driverResponse.data.driver);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        console.error("No access token found");
      }
    }

    fetchData();

  }
    , []);
  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
          {/* navigation button */}
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        </Card>
      </div>
    </div>
  );
};

export default NewSchedule;

export const tableEquipment = [
  {
    Header: 'Fleet Number',
    accessor: 'fleetNumber',
  },
  {
    Header: 'Manufacturer',
    accessor: 'manufacturer',

  },
  {
    Header: 'Model',
    accessor: 'model',
  },
  {
    Header: 'Last Service',
    accessor: 'lastService',
  },
  {
    Header: 'Next Service',
    accessor: 'nextService',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'button',
  },
];

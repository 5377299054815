import Barcode from "assets/img/ecommerce/Code-128.png";
const Details = (data) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-between rounded-[20px] bg-white bg-clip-border px-[35px] pt-8 pb-11 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-row">
      {/* left side */}
      <div className="flex flex-col items-center gap-16 md:flex-row">
        <div>
          <p className="text-base font-medium leading-6 text-gray-600">
            Customer Details:
          </p>
          <h3 className="text-xl font-bold leading-7 text-navy-700 dark:text-white">
            {data.siteContact[0].fullname}
          </h3>
          <p className="text-base font-medium leading-6 text-gray-600">
            {String(data.siteData[0].address + ",").toUpperCase()}
          </p>
          <p className="text-base font-medium leading-6 text-gray-600">
            {String(data.siteData[0].postcode).toUpperCase()}, United Kingdom
          </p>
        </div>
        <div>
          <p className="text-base font-medium text-gray-600">{data.siteContact[0].contactNumber}</p>
          <p className="font-medium text-navy-700">
            <a
              className="text-brand-500 underline hover:text-brand-500 dark:text-white"
              href=" "
            >
              {data.siteContact[0].email}
            </a>
          </p>

        </div>
      </div>
      {/* barcode image 
      <div className="mt-5 h-[112px] w-[112px] rounded-[20px] md:!mt-0">
        <img src={Barcode} className="h-full w-full" alt="barcode" />
      </div>*/}
    </div>
  );
};

export default Details;
